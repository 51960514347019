// stylelint-disable declaration-no-important

.bd-pageheader {
  padding: 2rem ($grid-gutter-width / 2);
  margin-bottom: 1.5rem;
  color: $bd-purple-light;
  text-align: center;
  background-color: $bd-purple;

  .container {
    position: relative;
  }

  h1 {
    font-size: 3rem;
    font-weight: 400;
    color: #fff;
  }

  p {
    margin-bottom: 0;
    font-size: 1.25rem;
    font-weight: 300;
  }

  @include media-breakpoint-up(sm) {
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin-bottom: 3rem;
    text-align: left;

    .carbonad {
      margin: 2rem 0 0 !important;
    }
  }

  @include media-breakpoint-up(md) {
    h1 {
      font-size: 4rem;
    }

    p {
      font-size: 1.5rem;
    }
  }

  @include media-breakpoint-up(lg) {
    h1,
    p {
      margin-right: 380px;
    }

    .carbonad {
      position: absolute;
      top: 0;
      right: .75rem; // offset from the .container's padding
      margin: 0 !important;
    }
  }
}
